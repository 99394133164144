import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

	$('.logos_slider').slick({
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 6,
		slidesToScroll: 6,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}
		]
	});	

	$('.homepage__header__slider').slick({
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: true,
		prevArrow: $('.prev_header_slider'),
		nextArrow: $('.next_header_slider'),
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1
	});

	$('.sidebar__editors__slider').slick({
		arrows: true,
		autoplay: true,
		autoplaySpeed: 3000,
		prevArrow: $('.prev_editors'),
		nextArrow: $('.next_editors'),
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1
	});

	$('.brokers_reviews_slider').slick({
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: true,
		prevArrow: $('.prev'),
		nextArrow: $('.next'),
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 6,
		slidesToScroll: 6,
		responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 4
			}
		},
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			}
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}
		]
	});


});