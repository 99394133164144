import $ from 'jquery';

$(document).ready(function() {

    var $summary = $(".block_summary .dropdown-menu li a");
    var $summary_old = $(".sommaire .ul li a");

    $summary.add($summary_old).on('click', function(event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 300, function(){
                window.location.hash = hash;
            });
        }
    });

    $(".sommaire > p").click(function(e) {
        e.preventDefault();
        $(".sommaire ul").slideToggle(400);
    });

    $('a.referrer[target]').attr('rel','nofollow, noopener');
    $('a.dofollow[target]').attr('rel','noopener');

    $('.single-listing-post_expert').matchHeight();

    function checkOffset() {
        if($('.footer__newsletter').offset().top + $('.footer__newsletter').height() >= $('.footer__footer').offset().top - 0)
            $('.footer__newsletter').css('position', 'absolute');
        if($(document).scrollTop() + window.innerHeight < $('.footer__footer').offset().top)
            $('.footer__newsletter').css('position', 'fixed');
    }
    $(document).scroll(function() {
        checkOffset();
    });


    $(".footer__newsletter__box__close").click(function() {
        $(".footer__newsletter__box").removeClass('d-block');
        $(".footer__newsletter__box").addClass('d-none');
        document.cookie = "accepted_cookies=yes;"
        var btn_success = $(".footer__newsletter > a");
        setTimeout( function(){ btn_success.removeClass('d-none') }, 1000 );
        setTimeout( function(){ btn_success.addClass('d-block') }, 1000 );
    });

    $('.item_match_height').matchHeight();

    $(".button_show_breadcrumbs").click(function (){
        $(".show_breadcrumbs").slideToggle(300);
    });

    $("img").attr('loading', 'lazy');
    $("iframe").attr('loading', 'lazy');

	setTimeout(function() {
		$("#footer__newsletter").addClass("active");
	}, 10000);

});